import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getServiceCategoryFromId,
  getPriorityFromId,
  getContactWithFromId
} from '../../../../helper';
import TenantDetail from '../../../../components/TenantDetail';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getBuilding } from '../../../../redux/actions/building';
import { getOrganization } from '../../../../redux/actions/organization';
import LoadingAvatar from '../../../../components/LoadingAvatar';
import { UserRole } from '../../../users/constant';
import { LOADING_BLUE } from '../../../../utils/colorCode';
import {
  SettingsItem,
  SettingsItemContent,
  SettingsItemSubTitle,
  SettingsItemTitle
} from '../../../settings/SettingsItem';
import { FaArrowUp, FaCalendar, FaClock } from 'react-icons/fa';
import { Palette } from '../../../../components/utils';
import { SettingsModalBoldText } from '../../../settings/SettingsModals';
import { MdElectricBolt, MdMail } from 'react-icons/md';
import styled from 'styled-components';
import { Button } from '../../../../components/ui/Button';
import { openModal } from '../../../../helper/modals';
import { RequestStatus } from '../../constant';
import { IoChatboxEllipses } from 'react-icons/io5';
import moment from 'moment';

const LowerSection = ({ serviceStatus }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { service, categories } = useSelector((state) => state.services);
  const { building } = useSelector((state) => state.building);
  const [description, setDescription] = useState(null);
  const { organization } = useSelector((state) => state.Organization);
  const [reqState, setReqState] = useState({
    catergory: 'null',
    priority: '',
    contactWith: ''
  });
  const [categoryName, setCategoryName] = useState('');

  const { config, roleId } = useSelector((state) => state.auth);

  useEffect(() => {
    if (categoryName === 'Security/Change PIN Code') {
      if (roleId === UserRole.SUPER_ADMIN) {
        if (organization?.config?.show_user_pin_admin === false) {
          setDescription('PIN hidden for security reasons.');
        } else {
          setDescription(service && Object.keys(service).length && service.description);
        }
      } else {
        if (config?.show_user_pin_admin === false) {
          setDescription('PIN hidden for security reasons.');
        } else {
          setDescription(service && Object.keys(service).length && service.description);
        }
      }
    } else {
      setDescription(service && Object.keys(service).length && service.description);
    }
  }, [categoryName, config, organization, roleId, service]);

  useEffect(() => {
    if (roleId === UserRole.SUPER_ADMIN) {
      if (service?.building_id) {
        dispatch(
          getBuilding({
            id: service?.building_id
          })
        );
      }
    }
  }, [service, dispatch, roleId]);

  useEffect(() => {
    if (parseInt(building?.org_id)) {
      dispatch(
        getOrganization({
          id: building?.org_id
        })
      );
    }
  }, [building, dispatch]);

  useEffect(() => {
    if (service && Object.keys(service).length) {
      const category =
        categories && service.category_id
          ? getServiceCategoryFromId(categories, service.category_id)
          : '';
      const priority = getPriorityFromId(service.priority);
      const contactWith = getContactWithFromId(service.contact_type);
      setReqState((prev) => ({
        ...prev,
        catergory: category,
        priority: priority,
        contactWith: contactWith
      }));
      setCategoryName(category);
    }
  }, [service]);
  if (roleId === UserRole.SUPER_ADMIN && organization?.config?.show_user_pin_admin === undefined) {
    return <LoadingAvatar color={LOADING_BLUE} />;
  }

  return (
    <LowerContainer>
      <SettingsItem>
        <Button
          disabled={
            serviceStatus === RequestStatus.COMPLETE || serviceStatus === RequestStatus.ARCHIVED
          }
          text={'Edit'}
          height={'38px'}
          width={'100px'}
          isPrimary
          onClick={() => {
            openModal('serviceDetailEdit');
          }}
        />
      </SettingsItem>

      <SettingsModalBoldText style={{ paddingBottom: '10px', fontSize: '24px' }}>
        {t('Request details')}
      </SettingsModalBoldText>
      <SettingsItem>
        <FaClock size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
        <SettingsItemContent>
          <SettingsItemTitle>{t('Status')}</SettingsItemTitle>
          <SettingsItemSubTitle>
            {service && Object.keys(service).length && t(service.status)}
          </SettingsItemSubTitle>
        </SettingsItemContent>
      </SettingsItem>

      <SettingsItem>
        <MdElectricBolt size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
        <SettingsItemContent>
          <SettingsItemTitle>{t('Category')}</SettingsItemTitle>
          <SettingsItemSubTitle>{reqState.catergory}</SettingsItemSubTitle>
        </SettingsItemContent>
      </SettingsItem>

      <SettingsItem>
        <FaClock size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
        <SettingsItemContent>
          <SettingsItemTitle>{t('Urgency')}</SettingsItemTitle>
          <SettingsItemSubTitle>{t(reqState.priority)}</SettingsItemSubTitle>
        </SettingsItemContent>
      </SettingsItem>

      <SettingsItem>
        <MdMail size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
        <SettingsItemContent>
          <SettingsItemTitle>{t('Preferred method of contact')}</SettingsItemTitle>
          <SettingsItemSubTitle>{reqState.contactWith}</SettingsItemSubTitle>
        </SettingsItemContent>
      </SettingsItem>

      <SettingsItem>
        <FaArrowUp size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
        <SettingsItemContent>
          <SettingsItemTitle>
            {organization.config?.suite_enabled ? t('Room access') : t('Unit access')}
          </SettingsItemTitle>
          <SettingsItemSubTitle>
            {service && Object.keys(service).length && service.allow_access === true
              ? t('Allowed')
              : t('Not allowed')}
          </SettingsItemSubTitle>
        </SettingsItemContent>
      </SettingsItem>

      <SettingsItem>
        <FaCalendar size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
        <SettingsItemContent>
          <SettingsItemTitle>{t('Updated on:')}</SettingsItemTitle>
          <SettingsItemSubTitle>
            {service &&
              Object.keys(service).length &&
              service.last_update &&
              moment(service.last_update).format('MMMM DD, YYYY,')}
          </SettingsItemSubTitle>
        </SettingsItemContent>
      </SettingsItem>

      <SettingsItem>
        <IoChatboxEllipses size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
        <SettingsItemContent>
          <SettingsItemTitle>Description</SettingsItemTitle>
          <SettingsItemSubTitle>{service.description || 'None'}</SettingsItemSubTitle>
        </SettingsItemContent>
      </SettingsItem>

      <SettingsModalBoldText
        style={{ paddingBottom: '10px', fontSize: '24px', paddingTop: '14px' }}>
        {t('Resident details')}
      </SettingsModalBoldText>
      <TenantDetail
        building={
          service && Object.keys(service).length && service.tenant && service.tenant.building
        }
        floor={service && Object.keys(service).length && service.tenant && service.tenant.floor}
        unit={service && Object.keys(service).length && service.tenant && service.tenant.unit_num}
        name={service && Object.keys(service).length && service.tenant && service.tenant.name}
      />
    </LowerContainer>
  );
};

const LowerContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 24px;

  @media (min-width: 756px) {
    height: 450px;
  }

  @media (min-width: 1310px) {
    height: 480px;
  }

  @media (min-width: 1610px) {
    height: 725px;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background-color: none;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: none;
  }

  :hover {
    ::-webkit-scrollbar-track {
      display: none;
    }

    ::-webkit-scrollbar {
      width: 0px;
      background-color: none;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: ${Palette.HAZY_BLUE} !important;
    }
  }
`;

export default LowerSection;
