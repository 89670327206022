import React, { useMemo } from 'react';
import { CHeader, CHeaderNav } from '@coreui/react';
import { TheHeaderDropdownMssg } from './index';
import TheHeaderDropDownModal from './TheHeaderDropDownModal';
import GlobalBuildingDropDown from '../components/GlobalBuildingDropDown';
import { useLocation, matchPath } from 'react-router-dom';
import styled from 'styled-components';
import { MediaQuery, Palette } from '../components/utils';
import routes from '../routes';
import {
  Breadcrumb,
  BreadcrumbIcon,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator
} from '../components/ui/Breadcrumb';
import DefaultBreadcrumb from '../components/breadcrumbs/DefaultBreadcrumb';

const TheHeader = () => {
  const location = useLocation();

  const memoHeaderTitle = useMemo(() => {
    for (const route of routes) {
      const match = matchPath(location.pathname, { path: route.path, exact: true });
      if (match) {
        // If there is no breadcrumb, then just return the pathname as the breadcrumb
        if (!route.breadcrumb) {
          return <DefaultBreadcrumb routeName={route.name} href={location.pathname} />;
        }

        // /building/:id
        // /building/2
        const matchSegments = match.path.split('/').filter(Boolean);
        const locationSegments = location.pathname.split('/').filter(Boolean);

        return matchSegments.reduce((acc, current, index) => {
          // Lookup if the breadcrumb is mapped
          const mappedBreadcrumb = route.breadcrumb[current];
          if (!mappedBreadcrumb) return acc;

          const to = `/${locationSegments.slice(0, index + 1).join('/')}`;

          // Add the separator
          if (acc.length > 0) {
            acc.push(<BreadcrumbSeparator key={to} />);
          }

          if (typeof mappedBreadcrumb === 'function') {
            acc.push(
              React.createElement(mappedBreadcrumb, {
                key: to,
                match,
                location,
                route,
                href: to
              })
            );
          } else {
            acc.push(<DefaultBreadcrumb key={to} routeName={route.name} href={to} />);
          }

          return acc;
        }, []);
      }
    }
    return [];
  }, [location.pathname, routes]);

  return (
    <StyledHeader>
      <StyledHeaderNav>
        <HeaderLeft>
          <Breadcrumb>
            <BreadcrumbList>{memoHeaderTitle}</BreadcrumbList>
          </Breadcrumb>
        </HeaderLeft>
        <HeaderRight>
          <HeaderDropdown>
            <GlobalBuildingDropDown />
          </HeaderDropdown>
          <TheHeaderDropDownModal />
          <TheHeaderDropdownMssg />
        </HeaderRight>
      </StyledHeaderNav>
    </StyledHeader>
  );
};

const StyledHeader = styled(CHeader)`
  background-color: ${Palette.GHOST_WHITE};
  padding: 0;
`;

const StyledHeaderNav = styled(CHeaderNav)`
  display: flex;
  margin-right: auto;
  justify-content: space-between !important;
  width: 100%;
`;

const HeaderLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2.5rem;
`;

const HeaderTitle = styled.p`
  display: none;
  color: ${Palette.BLACK};
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 0;

  @media ${MediaQuery.laptopM} {
    display: flex;
  }
`;

const HeaderDropdownContainers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  row-gap: 1rem;

  @media ${MediaQuery.laptopS} {
    flex-direction: row;
  }
`;

const HeaderDropdown = styled.div`
  width: 200px;
`;

const HeaderRight = styled.div`
  display: none;
  column-gap: 0.75rem;

  @media ${MediaQuery.tablet} {
    display: flex;
  }
`;
export default React.memo(TheHeader);
