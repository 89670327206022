import { CCol, CImg, CRow } from '@coreui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../../../../redux/actions/headerModal';

import { useTranslation } from 'react-i18next';
import DetailsModalTitleButtons from '../../../../components/DetailsModalTitleButtons';
import moment from 'moment/moment';
import { RequestStatus } from '../../constant';
import { InputSeparator, SettingsModalBoldText } from '../../../settings/SettingsModals';
import { StyledDateText } from '../../ServiceComponents';
const UpperSection = () => {
  const { service } = useSelector((state) => state.services);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  moment.locale(i18n.resolvedLanguage);

  return (
    <>
      <CRow className="imgAndDetailDiv">
        <CCol>
          <InputSeparator>
            <SettingsModalBoldText>
              {service && Object.keys(service).length && service.subject}
            </SettingsModalBoldText>
            <StyledDateText>
              {t('Requested on')}{' '}
              {service &&
                Object.keys(service).length &&
                service.create_date &&
                moment(service.create_date).format('MMMM DD, YYYY, hh:mm a')}
            </StyledDateText>
            <StyledDateText>
              {t('Reference')}: {service && service.reference}
            </StyledDateText>
          </InputSeparator>
        </CCol>
      </CRow>
    </>
  );
};

export default UpperSection;
