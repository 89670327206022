import React, { useEffect, useState } from 'react';
import { setToast } from '../../redux/actions/window';
import { CCol, CRow } from '@coreui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Row } from 'antd';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import ModalFooter from '../../components/ModalFooter';
import UserAsyncDropDown from '../../components/UserAsyncDropDown';
import { getAllBuildings } from '../../redux/actions/building';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import { clearServiceError, createService, getServices } from '../../redux/actions/services';
import {
  getUser,
  getUserDeviceSlots,
  setUserDeviceSlots,
  setUserSearch
} from '../../redux/actions/user';
import { CONTACT_WITH_MAP, PRIORITY_MAPPING } from '../../utils/mappings';
import { useTranslation } from 'react-i18next';
import { categoryTypes } from './categories';
import { UserRole } from '../users/constant';
import localization from 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';
import localeEn from 'antd/es/date-picker/locale/en_US';
import i18next from 'i18next';
import {
  PinRelatedCategoryId,
  RequestCategoryAddGuestPIN,
  RequestCategoryRemoveGuestPIN,
  RequestCategoryChangePIN
} from './constant';
import {
  searchTable,
  setSearchField,
  setSearchPagination,
  setSearchState,
  setShouldClearQuery
} from '../../redux/actions/search';
import { ENTITY } from '../../utils/searchConstant';
import { ModalButtons, ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import { Select, SelectItem } from '../../components/ui/Select';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../settings/SettingsModals';
import styled from 'styled-components';
import { TextArea } from '../../components/ui/TextArea';
import { CustomDatePicker } from '../../components/CustomSearchBar';
import { ErrorText } from '../../components/utils/AvatarCropper';
import { SEARCH_TABLES } from '../../helper/constants';

const ServiceCreate = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  moment.locale(i18n.resolvedLanguage, localization);
  const localLang = i18next.resolvedLanguage === 'fr' ? locale : localeEn;

  const servicesLabels = {
    category: t('Category'),
    subject: t('Subject'),
    description: t('Description'),
    contact_method: t('Preferred method of contact'),
    urgency: t('Urgency'),
    organization: t('Organization'),
    building: t('Building'),
    tenant: t('Resident'),
    assigned_to: t('Assigned to'),
    scheduled_on: t('Scheduled on')
  };
  const searchState = useSelector((state) => state.search);
  const { config } = useSelector((state) => state.auth);
  const { modal } = useSelector((state) => state.headerModal);
  const { globalOrgId } = useSelector((state) => state.Organization);

  const { categories, error, status, searchStatus } = useSelector((state) => state.services);
  const { deviceSlots, user } = useSelector((state) => state.user);
  const { allBuildings } = useSelector((state) => state.building);
  const { AllOrganizations, organization } = useSelector((state) => state.Organization);

  const { roleId } = useSelector((state) => state.auth);

  const [assignedToUservalue, setAssignedToUservalue] = useState({});
  const [availableSlot, setAvailableSlot] = useState(null);
  const [userPinLen, setUserPinLen] = useState(0);
  const [localError, setLocalError] = useState('');
  const [isPrevPinReq, setIsPrevPinReq] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (config) {
      if (roleId != UserRole.SUPER_ADMIN) {
        setUserPinLen(config['user_pin_len']);
      }
    }
  }, [config]);

  const isPinCategory = (categoryString) => {
    if (!categoryString || typeof categoryString !== 'string') {
      return false;
    }
    return parseInt(categoryString.split(',')[0], 10) === PinRelatedCategoryId;
  };

  const _verifyPinCategory = (type) => (categoryString) => {
    if (!categoryString || typeof categoryString !== 'string') {
      return false;
    }
    const categoryInfo = categoryString.split(',');
    if (!parseInt(categoryString.split(',')[0], 10) === PinRelatedCategoryId) {
      return false;
    }

    return categoryInfo[1] === type;
  };

  const isRemoveGuestPin = _verifyPinCategory(RequestCategoryRemoveGuestPIN);

  const isAddGuestPin = _verifyPinCategory(RequestCategoryAddGuestPIN);

  const isChangePin = _verifyPinCategory(RequestCategoryChangePIN);

  const schema = Yup.object().shape({
    user: Yup.object()
      .required(t('User is required'))
      .test('is-required', t('User is required'), (value, context) => {
        if (!value || value == '' || value?.value < 1) return false;
        return true;
      })
      .test('no-available-slot', '', (value, context) => {
        if (deviceSlots && deviceSlots.length === 0) {
          if (isAddGuestPin(context.parent.category) && value && value?.value > 0) {
            if (localError != '') {
              return false;
            }
            return true;
          }
        }
        return true;
      }),
    removeSlot: Yup.string().test(
      'is-required',
      t('Select a guest to remove'),
      (value, context) => {
        if (isRemoveGuestPin(context.parent.category)) {
          if (value && value.split('-')[0] >= 2) return true;
          return false;
        }
        return true;
      }
    ),

    guest: Yup.string().test('is-required', t('Enter a guest name'), (value, context) => {
      if (isAddGuestPin(context.parent.category)) {
        return value != '' && value != null;
      }
      return true;
    }),

    building_id: Yup.string()
      .required(t('Select a building'))
      .test('is-required', t('Select a building'), (value, context) => {
        if (!value || value == '' || parseInt(value) < 1) return false;
        return true;
      }),
    subject: Yup.string()
      .matches(/^[a-zA-Z0-9 ]{0,64}$/i, t('Incorrect Format,only alphanumeric values'))
      .max(64, t('Not more than 64 character'))
      .required(t('Enter the subject')),
    category: Yup.string()
      .required(t('Select a category'))
      .test('is-required', t('Select a category'), (value, context) => {
        if (!value || value == '' || parseInt(value) < 1) return false;
        return true;
      }),
    description: Yup.string()

      .matches(/^[a-zA-Z0-9,.!?" ]{0,500}$/i, t('Incorrect Format,only alphanumeric values'))
      .max(500, t('Not more than 500 character'))

      .test('is-pin-required', t('Enter a PIN number'), (value, context) => {
        if (isAddGuestPin(watchCategory) || isChangePin(watchCategory)) {
          if (isPinCategory(context.parent.category)) {
            if (value == '' || !value) return false;
            return true;
          }
          return true;
        }
        return true;
      })

      .test(
        'not-repetition',
        t("Can't include duplicated numbers (i.e 3333)"),
        (value, context) => {
          if (!isPinCategory(context.parent.category)) return true;

          if (/(\d)\1{3}/g.test(value)) {
            return false;
          }
          return true;
        }
      )

      .test('not-sequence', t("Can't include consecutive numbers (i.e 1234)"), (value, context) => {
        const categoryType = context.parent.category.split(',')[0];
        if (categoryType != categoryTypes.SECURITY) return true;
        if (
          /(?=0123|1234|2345|3456|4567|5678|6789|9876|8765|7654|6543|5432|4321|3210)/g.test(value)
        ) {
          return false;
        }
        return true;
      })

      .test('only-numbers', t('Only numbers are allowed for PIN'), (value, context) => {
        if (isAddGuestPin(context.parent.category) || isChangePin(context.parent.category)) {
          if (/^[0-9]*$/g.test(value)) return true;
          return false;
        }
        return true;
      })

      .test(
        'user_pin_len',
        t('pin_number', { userPinLen: userPinLen }),

        (value, context) => {
          if (userPinLen === 0) return true;

          if (isAddGuestPin(context.parent.category) || isChangePin(context.parent.category)) {
            if (value && value.length != userPinLen) {
              return false;
            }
            return true;
          }
          return true;
        }
      )

      .test('is-required', t('Give a short description'), (value, context) => {
        if (
          isAddGuestPin(context.parent.category) ||
          isChangePin(context.parent.category) ||
          isRemoveGuestPin(context.parent.category)
        )
          return true;

        if (!value) return false;
        return value.length > 0;
      }),

    scheduledDate: Yup.string().required('Schedule a date').nullable(),

    contact_type: Yup.number()
      .required(t('Select how to contact you'))
      .test('is-required', t('Select how to contact you'), (value, context) => {
        if (!value || value == '' || parseInt(value) < 1) return false;
        return true;
      })

      .test(
        'resource-not-exist',
        t("Resident doesn't have this contact type defined"),
        (value, context) => {
          if (user?.email || user?.cell_phone) {
            switch (parseInt(value)) {
              case 1:
                if (!user?.email || user?.email === '') return false;
                break;
              case 2:
                if (!user?.cell_phone || user?.cell_phone === '') return false;
                break;
              default:
                break;
            }
          }
          return true;
        }
      ),
    priority: Yup.number()
      .required(t('Select your urgency'))
      .test('is-required', t('Select you urgency'), (value, context) => {
        if (!value || value == '' || parseInt(value) < 1) return false;
        return true;
      }),
    organization: Yup.string().test(
      'org-required',
      t('Select an organization'),
      (value, context) => {
        if (roleId == UserRole.SUPER_ADMIN) {
          return value != null && value != '' && parseInt(value) > 0;
        }
        return true;
      }
    )
  });

  const {
    control,
    setValue,
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    clearError,
    trigger,
    formState: { errors, isDirty, isValid, dirtyFields },
    resetField,
    getValues
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      category: '',
      removeSlot: -1
    }
  });

  useEffect(() => {}, [errors]);

  const watchAllFields = watch();
  const watchUser = watch('user');
  const watchCategory = watch('category');
  const watchSubject = watch('subject');
  const watchDescription = watch('description');
  const watchOrganization = watch('organization');
  const watchBuilding = watch('building_id');

  useEffect(() => {
    if (watchUser?.value) {
      dispatch(
        getUser({
          id: watchUser?.value
        })
      );
    }
  }, [watchUser, dispatch]);

  useEffect(() => {
    if (watchOrganization) {
      dispatch(
        getAllBuildings({
          id: parseInt(watchOrganization)
        })
      );
    }

    // Get user_pin_len or the selected organization
    if (watchOrganization) {
      const userPinLen = AllOrganizations.find((o) => o.id === parseInt(watchOrganization))?.config
        ?.user_pin_len;

      setUserPinLen(userPinLen);
    }
  }, [watchOrganization, dispatch]);

  useEffect(() => {
    if (userPinLen > 0 && roleId === UserRole.SUPER_ADMIN && watch('description')) {
      trigger('description');
    }
  }, [userPinLen]);

  useEffect(() => {
    if (isPinCategory(watchCategory)) {
      setIsPrevPinReq(true);
      switch (watchCategory.split(',')[1]) {
        case RequestCategoryChangePIN:
          setValue('subject', 'Change my PIN request');
          break;
        default:
          setValue('subject', '');
          break;
      }
    } else if (isPrevPinReq) {
      setIsPrevPinReq(false);
      setValue('subject', '');
    }
  }, [watchCategory, setValue]);

  useEffect(() => {
    if (isPinCategory(watchCategory)) {
      if (watchCategory.split(',')[1] === RequestCategoryAddGuestPIN) {
        dispatch(
          getUserDeviceSlots({
            id: watchUser?.value,
            no_codes: true,
            only_empty: true,
            first_slot: 1,
            last_slot: 11
          })
        );
      } else {
        dispatch(
          watchUser?.value > 0
            ? getUserDeviceSlots({
                id: watchUser?.value
              })
            : setUserDeviceSlots({
                deviceSlots: []
              })
        );
      }
    }
  }, [watchUser, dispatch, watchCategory, watchOrganization, watchBuilding]);

  useEffect(() => {
    if (deviceSlots && deviceSlots.length === 0) {
      if (isAddGuestPin(watchCategory) && watchUser) {
        setLocalError(t('There is no available slots'));

        setAvailableSlot(null);
      }
    } else {
      setLocalError('');
      setAvailableSlot(deviceSlots[0]?.slot);
    }
  }, [deviceSlots]);

  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
    dispatch(clearServiceError());
  };

  const customSubmit = (values) => {
    setIsSubmitting(true);
    let removeGuestPIN = 0;
    let slot_pin_name = '';
    if (values.removeSlot) {
      for (const slot of deviceSlots) {
        if (parseInt(slot.slot) === parseInt(values.removeSlot.split('-')[0])) {
          removeGuestPIN = slot.code;
          slot_pin_name = slot.slot_pin_name;
        }
      }
    }
    const myCategory = parseInt(values.category.split(',')[0], 10);
    const mySubCategory = values.category.split(',')[1];

    let myDescription = values.description;
    let slot = 0;
    let action;
    let type;
    let name;
    switch (mySubCategory) {
      case RequestCategoryChangePIN:
        name = '';
        type = 'user';
        action = 'change_pin';
        slot = 1 - 1;
        break;
      case RequestCategoryAddGuestPIN:
        name = values?.guest;
        type = 'guest';
        action = 'add';
        slot = parseInt(availableSlot) - 1; // use next available slot
        break;
      case RequestCategoryRemoveGuestPIN:
        name = slot_pin_name;
        type = 'guest';
        action = 'delete';
        slot = parseInt(values.removeSlot) - 1; // use the slot selected by user
        myDescription = removeGuestPIN;
        break;
      default:
        slot = 0;
    }

    let myContactInfo;
    switch (parseInt(values.contact_type)) {
      case 1:
        myContactInfo = user?.email;
        break;
      case 2:
        myContactInfo = user?.cell_phone;
        break;
      default:
        myContactInfo = null;
    }

    let subValues = {
      user_id: parseInt(values.user?.value), //  values.user_id
      building_id: parseInt(values.building_id),
      subject: values.subject,
      category_id: '',
      category: myCategory,
      description: myDescription,
      media: '',
      last_update: '',
      create_date: '',
      scheduled_date: moment(values.scheduledDate).utcOffset(0, true).toISOString(),
      status: null,
      assigned_to: values.assignedTo ? parseInt(values.assignedTo.value) : null,
      time_slot: null,
      close_date: '',
      contact_type: parseInt(values.contact_type),
      priority: parseInt(values.priority),
      allow_access: false,
      contact_info: myContactInfo
    };
    let data = {};
    if (myCategory === PinRelatedCategoryId) {
      data = {
        action: action,
        type: type,
        name: name,
        status: 'Requested',
        slot: slot
      };
    }

    subValues = {
      ...subValues,
      data: data
    };
    dispatch(
      createService({
        body: subValues,
        success: () => {
          dispatch(getServices({ id: 0, page: 1, orgId: globalOrgId, status: searchStatus }));
          dispatch(changeOpenedModal({ modal: '' }));
          dispatch(setSearchField({ entity: ENTITY.request, value: null }));
          dispatch(setShouldClearQuery(true));
          dispatch(
            searchTable({
              entity: SEARCH_TABLES.WORK_REQUESTS,
              orgID: organization.id,
              body: {
                search: searchState.search,
                minCreationDate: searchState.minCreationDate,
                maxCreationDate: searchState.maxCreationDate,
                filters: searchState.filters,
                ordering: searchState.ordering,
                pagination: searchState.pagination
              },
              success: (res) => {
                dispatch(
                  setSearchState({
                    currentData: res.data.data ?? [],
                    currentPaginationData: res.data.pagination
                  })
                );

                if (!res.data.data) {
                  dispatch(setUserSearch({ page: 1 }));
                  dispatch(
                    setSearchPagination({
                      page: 1,
                      limit: 20
                    })
                  );
                }
              }
            })
          );
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Service request has been successfully created!')
            })
          );
          setIsSubmitting(false);
        },

        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Service request creation failed!'),
              modal: 'errorToast'
            })
          );
          setIsSubmitting(false);
        }
      })
    );
  };

  function disabledDate(current) {
    // Can not select days before today
    return current < moment().startOf('day');
  }
  const handleCancel = () => {
    onClose();
  };

  return (
    <form name="createServiceReqForm" onSubmit={handleSubmit(customSubmit)}>
      <StyledModal
        show={modal === 'createService' ? true : false}
        onClose={onClose}
        closeOnBackdrop={false}>
        <ModalHeader title={t('Create service request')} />
        <StyledModalBody id={'service-request-modal-body'}>
          <SettingsModalBoldText fontSize={'28px'}>{t('Request details')}</SettingsModalBoldText>
          <InputSeparator>
            <SettingsModalBoldText>{servicesLabels.category}</SettingsModalBoldText>
            <Controller
              control={control}
              name="category"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => {
                return (
                  <Select
                    loadingMessage={() => t('Loading...')}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    onBlur={onBlur}
                    id="category"
                    custom
                    placeholder={'Please select'}
                    value={value || undefined}
                    valid={!errors.category && (isDirty || isValid)}
                    error={!!errors.category && (isDirty || !isValid)}>
                    {categories &&
                      categories.map((item) =>
                        parseInt(roleId) != UserRole.SUPER_ADMIN &&
                        config.org_allow_edits == false ? (
                          item.ID === 35 && (
                            <SelectItem
                              id={`${item.Name},${item.SubCategory}`}
                              key={`${item.ID},${item.SubCategory}`}
                              value={`${item.ID},${item.SubCategory}`}>
                              {item.Name}/{item.SubCategory}
                            </SelectItem>
                          )
                        ) : (
                          <SelectItem
                            id={`${item.Name},${item.SubCategory}`}
                            key={`${item.ID},${item.SubCategory}`}
                            value={`${item.ID},${item.SubCategory}`}>
                            {item.Name}/{item.SubCategory}
                          </SelectItem>
                        )
                      )}
                  </Select>
                );
              }}
            />

            {errors.category && (dirtyFields?.category || !isValid) && (
              <ErrorText>{errors.category?.message}</ErrorText>
            )}
          </InputSeparator>

          {isAddGuestPin(watchCategory) ? (
            <>
              <InputSeparator>
                <SettingsModalBoldText>{t('Guest name')}</SettingsModalBoldText>
                <Controller
                  control={control}
                  name="guest"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (
                    <SettingsModalInput
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      valid={!errors.guest && (isDirty || isValid)}
                      error={!!errors.guest && (isDirty || !isValid)}
                      name="guest"
                      id="guest"
                      type="text"
                      placeholder={t('Guest name')}
                    />
                  )}
                />

                {errors.guest && (dirtyFields?.guest || !isValid) && (
                  <ErrorText>{errors.guest?.message}</ErrorText>
                )}
              </InputSeparator>
            </>
          ) : (
            ''
          )}

          <InputSeparator>
            <CRow className="subjectRow">
              <CCol>
                <SettingsModalBoldText>{servicesLabels.subject}</SettingsModalBoldText>
              </CCol>
              <CCol
                className={
                  watchSubject && watchSubject?.length >= 64
                    ? 'rightCol limtExceedtext-right text-right'
                    : 'rightCol text-right'
                }>
                {watchSubject ? watchSubject?.length : 0}/64
              </CCol>
            </CRow>
            <Controller
              control={control}
              name="subject"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (
                <SettingsModalInput
                  maxLength={64}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  disabled={isPinCategory(watchCategory)}
                  valid={!errors.subject && (isDirty || isValid)}
                  error={!!errors.subject && (isDirty || !isValid)}
                  id="subject"
                  type="text"
                  placeholder={t('In short describe the subject...')}
                  autoComplete="off"
                />
              )}
            />
            {errors.subject && (dirtyFields?.subject || !isValid) && (
              <ErrorText>{errors.subject.message}</ErrorText>
            )}
          </InputSeparator>

          {!watchCategory ||
          (watchCategory &&
            (parseInt(watchCategory.split(',')[0], 10) !== PinRelatedCategoryId ||
              isChangePin(watchCategory) ||
              isAddGuestPin(watchCategory))) ? (
            <InputSeparator>
              <CRow className="descriptionRow">
                <CCol>
                  <SettingsModalBoldText>
                    {parseInt(watchCategory.split(',')[0], 10) === PinRelatedCategoryId
                      ? 'PIN'
                      : t(servicesLabels.description)}
                  </SettingsModalBoldText>
                </CCol>
                <CCol
                  className={
                    watchDescription && watchDescription.length >= 500
                      ? 'rightCol limtExceed text-right'
                      : 'rightCol text-right'
                  }>
                  {watchDescription ? watchDescription.length : 0}/500
                </CCol>
              </CRow>
              <Controller
                control={control}
                name="description"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState
                }) => (
                  <TextArea
                    maxLength={500}
                    rows={5}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    valid={!errors.description && (isDirty || isValid)}
                    error={!!errors.description && (isDirty || !isValid)}
                    id="description"
                    type="text"
                    placeholder={
                      parseInt(watchCategory.split(',')[0], 10) === PinRelatedCategoryId
                        ? 'PIN'
                        : t('Describe the problem you have...')
                    }
                  />
                )}
              />
              {errors.description && (dirtyFields?.description || !isValid) && (
                <ErrorText>{errors?.description.message}</ErrorText>
              )}
            </InputSeparator>
          ) : (
            ''
          )}
          <InputSeparator>
            <SettingsModalBoldText>{servicesLabels.contact_method}</SettingsModalBoldText>
            <Controller
              control={control}
              name="contact_type"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (
                <Select
                  loadingMessage={() => t('Loading...')}
                  onChange={onChange}
                  onBlur={onBlur}
                  custom
                  placeholder={'Please select'}
                  id="contact_type"
                  value={value}
                  valid={!errors.contact_type && (isDirty || isValid)}
                  error={!!errors.contact_type && (isDirty || !isValid)}>
                  {CONTACT_WITH_MAP.map((item) => (
                    <SelectItem key={item.id} value={item.id}>
                      {item.value}
                    </SelectItem>
                  ))}
                </Select>
              )}
            />
            {errors.contact_type && (dirtyFields?.contact_type || !isValid) && (
              <ErrorText>{errors?.contact_type.message}</ErrorText>
            )}
          </InputSeparator>
          <InputSeparator>
            <SettingsModalBoldText>{servicesLabels.urgency}</SettingsModalBoldText>
            <Controller
              control={control}
              name="priority"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (
                <Select
                  loadingMessage={() => t('Loading...')}
                  onChange={onChange}
                  onBlur={onBlur}
                  id="priority"
                  placeholder={'Please select'}
                  custom
                  value={value}
                  valid={!errors.priority && (isDirty || isValid)}
                  error={!!errors.priority && (isDirty || !isValid)}>
                  {PRIORITY_MAPPING.map((item, index) => (
                    <SelectItem key={item.id} value={item.id}>
                      {item.value}
                    </SelectItem>
                  ))}
                </Select>
              )}
            />

            {errors.priority && (dirtyFields?.priority || !isValid) && (
              <ErrorText>{errors?.priority.message}</ErrorText>
            )}
          </InputSeparator>
          <SettingsModalBoldText style={{ fontSize: '24px', paddingBottom: '16px' }}>
            {t('Resident details')}
          </SettingsModalBoldText>
          {roleId === UserRole.SUPER_ADMIN ? (
            <InputSeparator>
              <SettingsModalBoldText htmlFor="country">{t('Organization')}</SettingsModalBoldText>
              <Controller
                control={control}
                name="organization"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState
                }) => (
                  <Select
                    onBlur={onBlur}
                    onChange={(e) => {
                      resetField('building_id');
                      resetField('user');
                      resetField('assignedTo');
                      resetField('scheduledDate');
                      if (isRemoveGuestPin(watchCategory)) resetField('removeSlot');
                      onChange(e);
                    }}
                    custom
                    placeholder={'Select organization'}
                    id="organization"
                    value={value}
                    valid={!errors.organization && (isDirty || isValid)}
                    error={!!errors.organization && (isDirty || !isValid)}>
                    <SelectItem value={-1}>{t('Select organization')}</SelectItem>

                    {AllOrganizations &&
                      AllOrganizations.map((item, index) => (
                        <SelectItem value={item.id} key={index}>
                          {item.name}
                        </SelectItem>
                      ))}
                  </Select>
                )}
              />

              {errors.organization && (dirtyFields?.organization || !isValid) && (
                <ErrorText>{errors?.organization.message}</ErrorText>
              )}
            </InputSeparator>
          ) : (
            <></>
          )}
          <InputSeparator>
            <SettingsModalBoldText htmlFor="Building_ID">{t('Building')}</SettingsModalBoldText>
            <Controller
              control={control}
              name="building_id"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (
                <Select
                  disabled={roleId !== 99 ? false : !getValues('organization')}
                  name="building_id"
                  id="building_id"
                  placeholder={'Please select'}
                  onBlur={onBlur}
                  onChange={(e) => {
                    resetField('user');
                    resetField('assignedTo');
                    resetField('scheduledDate');
                    if (isRemoveGuestPin(watchCategory)) resetField('removeSlot');
                    onChange(e);
                  }}
                  value={value}
                  custom
                  valid={!errors.building_id && (isDirty || isValid)}
                  error={!!errors.building_id && (isDirty || !isValid)}>
                  {allBuildings?.length > 0 ? (
                    <SelectItem value={-1}>{t('Please select')}</SelectItem>
                  ) : (
                    <SelectItem value={-1}>{t('No Buildings Available')}</SelectItem>
                  )}

                  {allBuildings.map((building) => (
                    <SelectItem key={building.id} value={building.id}>
                      {building.name}
                    </SelectItem>
                  ))}
                </Select>
              )}
            />

            {errors.building_id && (dirtyFields?.building_id || !isValid) && (
              <ErrorText>{errors?.building_id.message}</ErrorText>
            )}
          </InputSeparator>
          <InputSeparator>
            <Row>
              <CCol className="p-0">
                <SettingsModalBoldText>{t('Resident')}</SettingsModalBoldText>
              </CCol>
            </Row>

            <Controller
              control={control}
              name="user"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (
                <UserAsyncDropDown
                  skip_deleted={true}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    if (isRemoveGuestPin(watchCategory)) resetField('removeSlot');
                    onChange(e);
                  }}
                  setValue={setValue}
                  type={1}
                  buildingId={watchBuilding}
                  only_residents={
                    parseInt(watchCategory.split(',')[0], 10) === PinRelatedCategoryId
                      ? true
                      : false
                  }
                  residentOnly={
                    parseInt(watchCategory.split(',')[0], 10) === PinRelatedCategoryId
                      ? true
                      : false
                  }
                  skip_coresidents={
                    parseInt(watchCategory.split(',')[0], 10) === PinRelatedCategoryId
                      ? true
                      : false
                  }
                />
              )}
            />

            {watchBuilding < 1 ||
              (errors.user && (dirtyFields?.user || !isValid) && (
                <ErrorText>{errors?.user.message}</ErrorText>
              ))}
          </InputSeparator>

          {isRemoveGuestPin(watchCategory) && (
            <InputSeparator>
              <Row>
                <CCol className="p-0">
                  <SettingsModalBoldText>{t('Select guest PIN to remove')}</SettingsModalBoldText>
                </CCol>
              </Row>
              <Controller
                control={control}
                name="removeSlot"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState
                }) => (
                  <Select
                    loadingMessage={() => t('Loading...')}
                    onChange={onChange}
                    onBlur={onBlur}
                    id="removeSlot"
                    custom
                    value={value}
                    valid={!errors.removeSlot && (isDirty || isValid)}
                    error={!!errors.removeSlot && (isDirty || !isValid)}
                    data={deviceSlots.map((slot) => {
                      if (slot?.slot >= 2) {
                        return (
                          <SelectItem
                            id={`${slot?.slot}-${slot?.device_id}-${slot?.slot_pin_name}`}
                            key={`${slot?.slot}-${slot?.device_id}-${slot?.slot_pin_name}`}
                            value={`${slot?.slot}-${slot?.device_id}-${slot?.slot_pin_name}`}>
                            {slot?.slot_pin_name}
                          </SelectItem>
                        );
                      }
                      return null;
                    })}
                  />
                )}
              />

              {errors.removeSlot && (dirtyFields?.removeSlot || !isValid) && (
                <ErrorText>{errors?.removeSlot.message}</ErrorText>
              )}
            </InputSeparator>
          )}

          <SettingsModalBoldText style={{ fontSize: '24px', paddingBottom: '16px' }}>
            {t('Service details')}
          </SettingsModalBoldText>
          <InputSeparator>
            <Row>
              <CCol className="p-0">
                <SettingsModalBoldText>{servicesLabels.assigned_to}</SettingsModalBoldText>
              </CCol>
            </Row>

            <Controller
              control={control}
              name="assignedTo"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (
                <UserAsyncDropDown
                  noUser={true}
                  skip_deleted={true}
                  value={value}
                  setValue={setAssignedToUservalue}
                  type={2}
                  placeholder={t('Please select')}
                  onBlur={onBlur}
                  onChange={onChange}
                  buildingId={watchBuilding}
                  error={error}
                />
              )}
            />
            {errors.assignedTo && dirtyFields?.assignedTo && (
              <ErrorText>{errors?.assignedTo.message}</ErrorText>
            )}
          </InputSeparator>
          <InputSeparator>
            <SettingsModalBoldText>{servicesLabels.scheduled_on}</SettingsModalBoldText>
            <Controller
              control={control}
              name="scheduledDate"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (
                <CustomDatePicker
                  className="form-control"
                  width={'100%'}
                  height={'56px'}
                  disabledDate={disabledDate}
                  showTime={{
                    format: 'hh:mm a'
                  }}
                  id="scheduledDate"
                  use12Hours
                  showSecond={false}
                  onChange={onChange}
                  onBlur={onBlur}
                  format="YYYY-MM-DD hh:mm a"
                  value={value}
                  placeholder={t('Select date')}
                  style={
                    !!errors.scheduledDate && (isDirty || !isValid)
                      ? {
                          border: '2px solid red'
                        }
                      : {}
                  }
                  locale={{
                    ...localLang,
                    lang: {
                      ...localLang.lang,
                      ok: 'OK'
                    }
                  }}
                />
              )}
            />

            {errors.scheduledDate && (dirtyFields?.scheduledDate || !isValid) && (
              <ErrorText>{errors?.scheduledDate.message}</ErrorText>
            )}
          </InputSeparator>
        </StyledModalBody>
        <ModalFooter
          text="Add"
          error={error?.message || error || (localError != '' && localError)}
          handleCancel={handleCancel}
          status={status === 'CREATE_SERVICE_PENDING' ? true : false}
          className={!isDirty ? 'btn disbale' : 'btn btn-primary'}
          disabled={isSubmitting || !isDirty}
        />
      </StyledModal>
    </form>
  );
};

const RemainingCharactersText = styled(CCol)``;

export default ServiceCreate;
