import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CCardBody } from '@coreui/react';
import Amenities from './Amenities/Amenities';
import Unit from './Unit/Unit';
import {
  getBuilding,
  getBuildingSuites,
  resetSuitesInBuilding,
  showGlobalBuildingsDropdown
} from '../../../redux/actions/building';
import { clearAmenities, clearFilteredAmenities } from '../../../redux/actions/amenities';
import { setIsOnDashboard, setToast } from '../../../redux/actions/window';
import AddUnit from './Unit/AddUnit';
import ExportCSVButton from '../../../components/ExportCSVButton';
import GlobalAddButton from '../../../components/GlobalAddButton';
import { useTranslation } from 'react-i18next';
import ImportUnitsCSVButton from '../../../components/ImportUnitsCSVButton';
import { UserRole } from '../../../views/users/constant';
import {
  AddNewButton,
  TablePageHeader,
  TablePageTitle
} from '../../../components/tables/TableComponents';
import { OptionsContainer } from '../../manage-users/ManageUserComponents';
import { FaArrowLeft } from 'react-icons/fa';
import styled from 'styled-components';
import { openModal } from '../../../helper/modals';
import { StyledTabLabel, Tab, TabList } from '../../../containers/TheHeaderDropdownMssg';
import { StyledCard } from '../../../components/Cards';
import { getOrgConfig } from '../../../redux/actions/organization';
import Suites from './Suites/Suites';
import CustomSearchBar from '../../../components/CustomSearchBar';
import {
  resetCSVSuites,
  resetSearchTextSuite,
  setCSVSuites,
  setSearchTextSuite
} from '../../../redux/actions/suites';
import { changeOpenedModal } from '../../../redux/actions/headerModal';

export const AVAILABLE_TABS = {
  SUITE: 'suite',
  UNITS: 'units',
  AMENITIES: 'amenities'
};

const UnitAmenities = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const building = useSelector((state) => state.building.building);
  const filteredAmenities = useSelector((state) => state.amenities.filteredAmenities);
  const csvSuites = useSelector((state) => state.suites.csvSuites);
  const searchText = useSelector((state) => state.suites.searchText);
  const orgConfig = useSelector((state) => state.Organization.orgConfig);
  const units = useSelector((state) => state.unit.units);
  const roleId = useSelector((state) => state.auth.roleId);
  const config = useSelector((state) => state.auth.config);
  const [chosenTab, setChosenTab] = useState(AVAILABLE_TABS.SUITE);
  const [preparedSuitesCSVData, setPreparedSuitesCSVData] = useState([]);
  const [activeSuitePage, setSuiteActivePage] = useState(1);
  const [itemsPerSuitePage, setItemsPerSuitePage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const firstRequest = useRef(true);

  const getSearchedSuites = () => {
    setIsLoading(true);
    dispatch(
      getBuildingSuites({
        orgId: building?.org_id,
        buildingId: building?.id,
        searchText: searchText
      })
    );
  };

  const emptySearchedSuited = () => {
    setIsLoading(true);
    dispatch(
      getBuildingSuites({
        orgId: building?.org_id,
        buildingId: building?.id
      })
    );
  };

  const openAddSuiteModal = () => {
    dispatch(
      changeOpenedModal({
        modal: 'addSuite'
      })
    );
  };

  const setSearchedText = (text) => {
    dispatch(setSearchTextSuite(text));
  };

  const getBuildingSuitesByPage = (page, orgId, buildingId) => {
    if (!page || !orgId || !buildingId) {
      return;
    }

    setIsLoading(true);
    dispatch(
      getBuildingSuites({
        orgId: orgId,
        buildingId: buildingId,
        page: page,
        per_page: itemsPerSuitePage,
        success: () => {},
        fail: () => {
          dispatch(setCSVSuites([]));
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Failed to fetch suites!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  useEffect(() => {
    if (activeSuitePage > 0 && orgConfig?.config?.suite_enabled) {
      getBuildingSuitesByPage(activeSuitePage, building?.org_id, building?.id);
    }
  }, [activeSuitePage]);

  useEffect(() => {
    const buildingIdFromUrl = history.location.pathname.split('/')[2];
    dispatch(resetSearchTextSuite());
    dispatch(
      getBuilding({
        id: buildingIdFromUrl,
        success: (buildingRes) => {
          dispatch(
            getOrgConfig({
              id: buildingRes?.data?.org_id,
              success: (data) => {
                firstRequest.current = false;
                setChosenTab(
                  data?.data?.config?.suite_enabled ? AVAILABLE_TABS.SUITE : AVAILABLE_TABS.UNITS
                );
                if (data?.data?.config?.suite_enabled) {
                  getBuildingSuitesByPage(
                    activeSuitePage,
                    buildingRes?.data?.org_id,
                    buildingRes?.data?.id
                  );
                }
              },
              fail: () => {
                dispatch(
                  setToast({
                    toastShow: true,
                    toastMessage: t('Failed to fetch the org config!'),
                    modal: 'errorToast'
                  })
                );
              }
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Failed to fetch the building!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  }, []);

  useEffect(() => {
    dispatch(setIsOnDashboard(false)); // to hide the global building DD
    return () => {
      dispatch(clearAmenities());
      dispatch(clearFilteredAmenities());
    };
  }, []);

  useEffect(() => {
    if (chosenTab === AVAILABLE_TABS.SUITE) {
      setPreparedSuitesCSVData(
        csvSuites.map((suite) => {
          return {
            name: suite?.name,
            area: suite?.area,
            floor: suite?.floor,
            rooms: suite?.rooms,
            battery: suite?.devices
              ?.map((device) => `${device.name} ${device?.battery_level}`)
              .join('; ')
          };
        })
      );
    }
  }, [chosenTab, csvSuites]);

  useEffect(() => {
    dispatch(showGlobalBuildingsDropdown(false));
    return () => {
      dispatch(showGlobalBuildingsDropdown(true));
    };
  }, []);

  return (
    <div>
      <StyledCard>
        <CCardBody>
          <TablePageHeader>
            <StyledLinkHeader>{building && <>{building.name}</>}</StyledLinkHeader>

            <OptionsContainer>
              {chosenTab === AVAILABLE_TABS.AMENITIES ? (
                <AddNewButton
                  disabled={roleId < UserRole.SUPER_ADMIN && config.org_allow_edits === false}
                  onClick={() => openModal('addAmenity')}
                />
              ) : chosenTab === AVAILABLE_TABS.SUITE ? (
                <>
                  <CustomSearchBar
                    setValue={setSearchedText}
                    handleSearch={getSearchedSuites}
                    handleGet={emptySearchedSuited}
                    // entitySearch={entity}
                    showSelect={false}
                    initialValue={searchText}
                  />
                  <AddNewButton
                    disabled={roleId < UserRole.SUPER_ADMIN && config.org_allow_edits === false}
                    onClick={openAddSuiteModal}
                  />
                </>
              ) : (
                config.org_allow_edits && <GlobalAddButton text="Add" modalName="addUnit" />
              )}
              {(roleId === UserRole.SUPER_ADMIN || roleId === UserRole.ADMIN) &&
                chosenTab !== AVAILABLE_TABS.AMENITIES &&
                config.org_allow_edits && <ImportUnitsCSVButton chosenTab={chosenTab} />}
              <ExportCSVButton
                Data={
                  chosenTab === AVAILABLE_TABS.AMENITIES
                    ? filteredAmenities
                    : chosenTab === AVAILABLE_TABS.SUITE
                    ? preparedSuitesCSVData
                    : units
                }
              />
            </OptionsContainer>
          </TablePageHeader>

          <TabList>
            {orgConfig?.config?.suite_enabled && (
              <Tab
                isActive={chosenTab === AVAILABLE_TABS.SUITE}
                onClick={() => setChosenTab(AVAILABLE_TABS.SUITE)}>
                <StyledTabLabel>{t('Units')}</StyledTabLabel>
              </Tab>
            )}
            <Tab
              isActive={chosenTab === AVAILABLE_TABS.UNITS}
              onClick={() => setChosenTab(AVAILABLE_TABS.UNITS)}>
              <StyledTabLabel>
                {t(orgConfig?.config?.suite_enabled ? 'Rooms' : 'Units')}
              </StyledTabLabel>
            </Tab>
            <Tab
              isActive={chosenTab === AVAILABLE_TABS.AMENITIES}
              onClick={() => setChosenTab(AVAILABLE_TABS.AMENITIES)}>
              <StyledTabLabel>{t('Amenities')}</StyledTabLabel>
            </Tab>
          </TabList>

          {chosenTab === AVAILABLE_TABS.SUITE ? (
            <Suites
              csvSuites={csvSuites}
              firstRequest={firstRequest}
              activePage={activeSuitePage}
              setActivePage={setSuiteActivePage}
              itemsPerPage={itemsPerSuitePage}
              setItemsPerPage={setItemsPerSuitePage}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          ) : chosenTab === AVAILABLE_TABS.AMENITIES ? (
            <Amenities />
          ) : (
            <Unit />
          )}

          <AddUnit />
        </CCardBody>
      </StyledCard>
    </div>
  );
};

const StyledLinkHeader = styled(TablePageTitle)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export default UnitAmenities;
