import i18next from 'i18next';
import { LOCK_PROVIDERS, ROLE_MAP } from './mappings';

export function getRoleNameByID(roleID) {
  const parsedRoleID = parseInt(roleID);
  return ROLE_MAP.find((type) => type.id === parsedRoleID)?.value || undefined;
}

export function getLockProviderNameByID(lockProviderId) {
  const parsedLockProviderID = parseInt(lockProviderId);
  return LOCK_PROVIDERS.find((type) => type.id === parsedLockProviderID)?.value || undefined;
}

export function getRoomUnitTerminology(organization) {
  return organization.config?.suite_enabled ? i18next.t('Room number') : i18next.t('Unit number');
}

// TODO: This function is temporary, in the future all options will be placed inside of the filters object
export function getAllSearchOptions(searchState) {
  return {
    search: searchState.search,
    minCreationDate: searchState.minCreationDate,
    maxCreationDate: searchState.maxCreationDate,
    category: searchState.category,
    building_id: searchState.building_id,

    filters: searchState.filters,
    ordering: searchState.ordering,
    pagination: searchState.pagination
  };
}

export function getFilterCount(searchState) {
  function countValidItems(value) {
    if (value === null || value === undefined) {
      return 0;
    }
    if (Array.isArray(value)) {
      return value.reduce((sum, item) => sum + countValidItems(item), 0);
    }
    if (typeof value === 'object') {
      return Object.values(value).reduce((sum, item) => sum + countValidItems(item), 0);
    }
    return 1;
  }

  return Object.values(searchState).reduce((sum, value) => sum + countValidItems(value), 0);
}