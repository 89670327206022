import React, { useEffect, useState } from 'react';
import { CCol, CModalFooter, CModalHeader, CModalTitle, CRow } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import UpperSection from './serviceDetailSubComponents/leftSection/UpperSection';
import LowerSection from './serviceDetailSubComponents/leftSection/LowerSection';
import ChatBox from './serviceDetailSubComponents/rightSection/ChatBox';
import Index from './serviceDetailSubComponents/rightSection/assignSchedule/index';
import StatusSection from './serviceDetailSubComponents/rightSection/StatusSection';
import LoadingAvatar from '../../components/LoadingAvatar';
import { useTranslation } from 'react-i18next';
import { clearOrganization } from '../../redux/actions/organization';
import { clearBuilding } from '../../redux/actions/building';
import { clearService, getServices, updateService, getService } from '../../redux/actions/services';
import { LOADING_BLUE } from '../../utils/colorCode';
import { AssignOperatorProcess } from './constant';
import { setToast } from '../../redux/actions/window';
import { RequestStatus } from './constant';
import { getStatusId } from '../../helper';
import { setSearchField, setShouldClearQuery } from '../../redux/actions/search';
import { ENTITY } from '../../utils/searchConstant';
import { ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import moment from 'moment';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalLightText
} from '../settings/SettingsModals';
import { StyledDateText } from './ServiceComponents';
import { Button } from '../../components/ui/Button';
import { ModalCloseButton } from '../settings/SettingsButton';
import { ButtonVariant } from '../../components/ui/ButtonVariant';
import { IoCloseOutline } from 'react-icons/io5';
import { Palette } from '../../components/utils';

const ServiceDetail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { modal } = useSelector((state) => state.headerModal);
  const { status, error, assignOperatorProcess, service, pagination, searchStatus } = useSelector(
    (state) => state.services
  );
  const { globalOrgId } = useSelector((state) => state.Organization);
  const { globalBuildingId } = useSelector((state) => state.building);
  const [originalStatus, setOriginalStatus] = useState(null);
  const [serviceStatus, setServiceStatus] = useState(null);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [shouldShowWarning, setShouldShowWarning] = useState(
    localStorage.getItem('shouldShowWarning') === 'false' ? false : true
  );

  useEffect(() => {
    if (!originalStatus) {
      setOriginalStatus(service.status);
    }
    setServiceStatus(service?.status);
  }, [service]);

  const onClose = () => {
    setServiceStatus(null);
    setShowWarningModal(false);
    dispatch(clearService());
    dispatch(clearBuilding());
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
  };

  const onUpdateSuccess = (callback) => {
    dispatch(
      getService({
        id: service.id,
        success: () => {
          dispatch(
            getServices({ id: globalBuildingId, page: 1, orgId: globalOrgId, status: searchStatus })
          );
          typeof callback === 'function' && callback();
        }
      })
    );
    dispatch(setSearchField({ entity: ENTITY.request, value: null }));
    dispatch(setShouldClearQuery(true));
  };

  const updateServiceRequestStatus = (newStatus, removeWarning) => {
    const serviceReq = {
      ...service,
      status_id: getStatusId(newStatus),
      assigned_to: newStatus === RequestStatus.OPEN ? 0 : service.assigned_to
    };

    if (removeWarning) {
      localStorage.setItem('shouldShowWarning', false);
      setShouldShowWarning(false);
    }

    dispatch(
      updateService({
        id: service.id,
        body: serviceReq,
        success: () => {
          onUpdateSuccess();
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Request status updated successfully!')
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Request status update failed!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  return (
    <>
      <StyledModal
        show={modal === 'serviceDetail'}
        closeOnBackdrop={false}
        onClose={onClose}
        width={'800px'}>
        <ModalHeader title={t('Service request details')} onCloseCallback={onClose} />
        <StyledModalBody>
          {status === 'GET_SERVICE_PENDING' ||
          assignOperatorProcess === AssignOperatorProcess.Pending ? (
            <LoadingAvatar color={LOADING_BLUE} />
          ) : status === 'GET_SERVICE_FAIL' ? (
            <div className="error_for_modal">
              <h4 className="text-danger">{error}</h4>
            </div>
          ) : (
            <>
              <CRow>
                <CCol>
                  <UpperSection />
                </CCol>
              </CRow>
              <CRow className="mx-0">
                <StatusSection
                  serviceStatus={serviceStatus}
                  setServiceStatus={setServiceStatus}
                  setShowWarningModal={setShowWarningModal}
                  updateServiceRequestStatus={updateServiceRequestStatus}
                  shouldShowWarning={shouldShowWarning}
                />
              </CRow>

              <CRow className="mx-0">
                <Index serviceStatus={serviceStatus} onUpdateSuccess={onUpdateSuccess} />
              </CRow>
              <CRow className="serviceDetailRow">
                <CCol className="mainLeftCol">
                  <LowerSection serviceStatus={serviceStatus} />
                </CCol>
                <CCol className="mainRightCol">
                  <ChatBox />
                </CCol>
              </CRow>
            </>
          )}
        </StyledModalBody>
      </StyledModal>
      {/* TODO: Fox modal header */}
      {modal === 'serviceDetail' && (
        <StyledModal show={showWarningModal} closeOnBackdrop={false} backdrop={false} centered>
          <StyledModalBody>
            <CModalHeader>
              <CModalTitle>Confirmation</CModalTitle>
              <div className="ml-auto align-self-start">
                <ButtonVariant
                  variant="outline"
                  size="icon"
                  onClick={() => setShowWarningModal(false)}>
                  <IoCloseOutline size={20} color={Palette.BLACK_50} />
                </ButtonVariant>
              </div>
            </CModalHeader>
            <SettingsModalBoldText>
              Please confirm this request is completed. This action is not reversible.
            </SettingsModalBoldText>
            <div className="flex">
              <input
                type="checkbox"
                className="mr-1 align-self-center"
                value={!shouldShowWarning}
                onClick={() => setShouldShowWarning((prev) => !prev)}
              />
              <SettingsModalLightText>Do not show this again</SettingsModalLightText>
            </div>
          </StyledModalBody>
          <CModalFooter>
            <Button onClick={() => setShowWarningModal(false)} text={'Close'} width={'100px'} />
            <Button
              isPrimary
              onClick={() => {
                setShowWarningModal(false);
                updateServiceRequestStatus(RequestStatus.COMPLETE, !shouldShowWarning);
              }}
              text={'Confirm'}
              width={'100px'}
            />
          </CModalFooter>
        </StyledModal>
      )}
    </>
  );
};

export default ServiceDetail;
