import React from 'react';
import BuildingsBreadcrumb from '../components/breadcrumbs/BuildingsBreadcrumb.jsx';
import DashboardBreadcrumb from '../components/breadcrumbs/DashboardBreadcrumb.jsx';

const Dashboard = React.lazy(() => import('../views/dashboard/Dashboard'));
const Users = React.lazy(() => import('../views/users/Users'));
const Organization = React.lazy(() => import('../views/properties/Organization/Organization'));
const Buildings = React.lazy(() => import('../views/properties/Building/Buildings'));
const UnitAmenities = React.lazy(() => import('../views/properties/Unit&Amenities/index.js'));

const Deals = React.lazy(() => import('../views/offers/Offers'));
const DeliveryCodes = React.lazy(() => import('../views/delivery-codes/DeliveryCodes.js'));

const Services = React.lazy(() => import('../views/services/Services'));
const Amenities = React.lazy(() => import('../views/amenities/Amenities'));
const Reports = React.lazy(() => import('../views/reports/Reports'));
const Template = React.lazy(() => import('../views/conversation/templates'));
const Drafts = React.lazy(() => import('../views/conversation/drafts'));
const Sent = React.lazy(() => import('../views/conversation/sent'));
const Page404 = React.lazy(() => import('../views/pages/page404/Page404'));
const Notification = React.lazy(() => import('../views/notifications'));
const Bookings = React.lazy(() => import('../views/bookings/index.js'));
const Security = React.lazy(() => import('../views/security/index.js'));
const PMAnalytics = React.lazy(() => import('../views/pm-analytics/orgs/PMAnalytics.js'));
const SetPassword = React.lazy(() => import('../views/pages/login/SetPassword.js'));
const Settings = React.lazy(() => import('../views/settings/Settings.js'));

const routes = [
  {
    path: '/notifications/',
    name: 'Notifications',
    component: Notification
  },
  {
    path: '/bookings',
    name: 'Bookings',
    component: Bookings
  },

  {
    path: '/conversation/templates',
    name: 'Templates',
    component: Template
  },
  {
    path: '/conversation/drafts',
    name: 'Drafts',
    component: Drafts
  },
  {
    path: '/conversation/sent',
    name: 'Sent',
    component: Sent
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    breadcrumb: { dashboard: DashboardBreadcrumb },
    component: Dashboard
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    exact: true
  },
  {
    path: '/organization',
    name: 'Organizations',
    component: Organization,
    exact: true
  },
  {
    path: '/buildings',
    name: 'My Buildings',
    component: Buildings,
    exact: true
  },
  {
    path: '/buildings/:id',
    name: 'My Buildings',
    breadcrumb: { buildings: 'My Buildings', ':id': BuildingsBreadcrumb },
    component: UnitAmenities,
    exact: true
  },
  {
    path: '/requests',
    name: 'Work Requests',
    component: Services
  },
  {
    path: '/amenities',
    name: 'Amenities',
    component: Amenities,
    exact: true
  },
  {
    path: '/deals',
    name: 'Deals',
    component: Deals,
    exact: true
  },
  {
    path: '/delivery_codes',
    name: 'Delivery Codes',
    component: DeliveryCodes,
    exact: true
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports
  },
  {
    path: '/security',
    name: 'PM Security',
    component: Security
  },
  {
    path: '/pm-analytics',
    name: 'PM Analytics',
    component: PMAnalytics,
    exact: true
  },
  {
    path: '/set-password',
    name: 'Set Password',
    component: SetPassword,
    exact: true
  },
  {
    path: '/settings',
    name: 'My Profile',
    component: Settings
  },
  {
    path: '*',
    name: 'Page Not Found',
    component: Page404
  }
];

export default routes;
